import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import {
    ReceoptionistMetadata,
    Receptionist,
    ReceptionistCall,
    CarrierResponse,
} from './types';

export default class ReceptionistService {
    public static createReceptionist({
        token,
        mobile,
        metadata,
    }: {
        token: string;
        mobile: string;
        metadata: ReceoptionistMetadata;
    }): Promise<AxiosResponse<Receptionist>> {
        return axios.post(
            '/receptionist/create',
            {
                mobile,
                metadata,
            },
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
    }

    public static getReceptionist({
        token,
        mobile,
    }: {
        token: string;
        mobile: string;
    }): Promise<AxiosResponse<Receptionist>> {
        return axios.post(
            `/receptionist/get`,
            {
                mobile,
            },
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
    }

    public static updateReceptionist({
        token,
        mobile,
        metadata,
    }: {
        token: string;
        mobile: string;
        metadata: any;
    }): Promise<AxiosResponse<Receptionist>> {
        return axios.patch(
            `/receptionist/update`,
            {
                mobile,
                metadata,
            },
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
    }

    public static getReceptionistCalls({
        token,
        mobile,
    }: {
        token: string;
        mobile: string;
    }): Promise<AxiosResponse<ReceptionistCall[]>> {
        return axios.post(
            `/receptionist/call/list`,
            {
                mobile,
            },
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
    }

    public static createReceptionistCall({
        token,
        sourceMobile,
        destinationMobile,
        transcript,
        metadata,
        startTimestamp,
        endTimestamp,
    }: {
        token: string;
        sourceMobile: string;
        destinationMobile: string;
        transcript: string;
        metadata: any;
        startTimestamp: string;
        endTimestamp: string;
    }): Promise<AxiosResponse<void>> {
        return axios.post(
            `/receptionist/call/create`,
            {
                source_mobile: sourceMobile,
                destination_mobile: destinationMobile,
                transcript,
                metadata,
                start_timestamp: startTimestamp,
                end_timestamp: endTimestamp,
            },
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
    }

    public static deleteCall({
        token,
        callId,
    }: {
        token: string;
        callId: string;
    }): Promise<AxiosResponse<void>> {
        return axios.post(
            `/receptionist/call/delete`,
            {
                call_id: callId,
            },
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
    }

    public static findCarrier({
        token,
        mobile,
    }: {
        token: string;
        mobile: string;
    }): Promise<AxiosResponse<CarrierResponse>> {
        return axios
            .post(
                `/receptionist/carrier`,
                {
                    mobile,
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    }

    public static requestTestCall({
        token,
        mobile,
    }: {
        token: string;
        mobile: string;
    }): Promise<AxiosResponse<void>> {
        return axios.post(
            `/receptionist/test/call`,
            {
                mobile,
            },
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
    }

    public static checkTestCallStatus({
        token,
        mobile,
        startTime,
    }: {
        token: string;
        mobile: string;
        startTime: string;
    }): Promise<AxiosResponse<void>> {
        return axios.post(
            `/receptionist/test/status`,
            {
                mobile,
                start_time: startTime,
            },
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
    }
}
