import { Button } from '@mui/material';
import { onWebAppCostEstimationEnterPrompt } from '../../../tracking/trackers';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ChatSVG from '../../../assets/icons/chat.svg';
import theme from '../../../theme';
import { Body } from '../../../components/Text';
import { TouchableOpacity } from '../../../components/Touchables';
import { useCostEstimator } from './CostEstimatorProvider';

const AddDescription = () => {
    const { width } = useWindowDimensions();
    const { prompt, setPrompt, setStep } = useCostEstimator();
    const handleSubmit = () => {
        setStep(2);
        onWebAppCostEstimationEnterPrompt({
            prompt,
        });
    };

    const demoPrompt = {
        'bathroom remodel': `bathroom remodel that involves renovating an 8' x 10' master bathroom by replacing the existing tub with a 60" x 36" walk-in shower, installing new porcelain floor and wall tiles, adding a 48" double vanity with quartz countertop, and updating all plumbing fixtures to brushed nickel. Includes new LED lighting, a modern toilet, and a 36" x 48" frameless mirror, plus fresh paint and an upgraded ventilation system.`,
        'interior painting': `interior painting that involves painting the walls, ceilings, and trim in a 12' x 14' living room with a vaulted ceiling. The project includes painting the walls and ceilings in a light gray color`,
        'kitchen remodel': `kitchen remodel that involves replacing the existing countertops with granite, installing new cabinetry with soft-close drawers and soft-close doors, updating the flooring to hardwood, and installing new lighting fixtures. The project includes replacing the existing countertops with granite, installing new cabinetry with soft-close drawers and soft-close doors, updating the flooring to hardwood, and installing new lighting fixtures.`,
    };

    return (
        <>
            <div
                style={{
                    width: Math.min(width * 0.5, 250),
                    height: Math.min(width * 0.5, 250),
                    backgroundColor: theme.colors.offWhite,
                    borderRadius: 1000,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    margin: 10,
                }}
            >
                <img src={ChatSVG} width={150} height={150} alt="chat" />
            </div>
            <div
                style={{
                    fontWeight: '700',
                    marginTop: 20,
                    marginBottom: 10,
                }}
            >
                Describe your project
            </div>
            <textarea
                placeholder="Include the type of work and any dimensions if you have them"
                rows={5}
                style={{
                    width: Math.min(width * 0.8, 400),
                    padding: 10,
                    borderRadius: 10,
                    borderColor: '#ccc',
                    borderWidth: 1,
                    fontSize: 16,
                    marginTop: 10,
                    maxWidth: 650,
                }}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
            />

            {/**Demo Prompt Section */}
            <div
                style={{
                    width: Math.min(width * 0.9, 400),
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Body
                    style={{
                        color: theme.colors.grayText,
                        marginBottom: 10,
                        fontSize: width < 350 ? 14 : 16,
                        textAlign: 'center',
                    }}
                >
                    Getting started? Try these:
                </Body>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 8,
                        justifyContent: 'center',
                    }}
                >
                    {Object.entries(demoPrompt).map(([key, value]) => (
                        <TouchableOpacity
                            key={key}
                            onClick={() => setPrompt(value)}
                            style={{
                                border: '1px solid #ccc',
                                padding: width < 350 ? '6px 12px' : '8px 16px',
                                borderRadius: 50,
                                backgroundColor: 'white',
                            }}
                        >
                            <Body
                                style={{
                                    color: theme.colors.grayText,
                                    fontSize: width < 350 ? 13 : 14,
                                }}
                            >
                                {key
                                    .split(' ')
                                    .map(
                                        (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                    )
                                    .join(' ')}
                            </Body>
                        </TouchableOpacity>
                    ))}
                </div>
            </div>

            <Button
                variant="contained"
                color="secondary"
                style={{
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: 25,
                    height: 50,
                    width: Math.min(width * 0.8, 400),
                    fontWeight: '700',
                    fontSize: 20,
                    marginTop: 30,
                }}
                onClick={handleSubmit}
                disabled={!prompt}
            >
                Submit
            </Button>
        </>
    );
};

export default AddDescription;
