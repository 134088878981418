import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useAuth } from '../../../providers/AuthProvider';
import theme from '../../../theme';
import {
    onWebAppClickFreemiumX,
    onWebAppConfirmPaymentTier,
    onWebAppLoadPaymentPreviewScreen,
    onWebAppToggleTaxAddOn,
} from '../../../tracking/trackers';
import OnboardingCardTemplate from '../../../components/OnboardingCardTemplate';
import AppRating from '../../../assets/icons/ios_rating.svg';
import { Body } from '../../../components/Text';
import { PAYMENT_PRICE_BASE, PAYMENT_PRICE_PRO } from '../../../util/constants';
import { BulletPoint } from './style';
import CheckableFeature from './CheckableFeature';

type IPaymentTiersProps = {
    goToPayment: (tier: 'base' | 'pro') => void;
    usageSurveyAnswer?: string;
    onPressFreemiumX: () => void;
    paywallVariant?: 'default' | 'cost-estimation' | 'voice-ai';
};

const PaymentTiers = (props: IPaymentTiersProps) => {
    const navigation = useNavigate();
    const { authLoading, isSubscribed } = useAuth();
    const { goToPayment, usageSurveyAnswer, onPressFreemiumX } = props;

    useEffect(() => {
        onWebAppLoadPaymentPreviewScreen({});
    }, []);

    const [taxModuleSelected, setTaxModuleSelected] = useState<boolean>(
        usageSurveyAnswer === 'both' || usageSurveyAnswer === 'business'
    );

    const onClickNext = () => {
        const tier = taxModuleSelected ? 'pro' : 'base';
        onWebAppConfirmPaymentTier({ tier });
        goToPayment(tier);
    };

    const _onPressFreemiumX = () => {
        onWebAppClickFreemiumX();
        onPressFreemiumX();
    };

    const makeProTierPersonalAndBusiness = usageSurveyAnswer === 'both';

    // useEffect(() => {
    //     // Safe-guard for users who already have a subscription
    //     if (
    //         isSubscribed &&
    //         props.paywallVariant !== 'cost-estimation' &&
    //         props.paywallVariant !== 'voice-ai'
    //     ) {
    //         navigation('/payment/success?alreadySubscribed=true');
    //     }
    // }, [isSubscribed]);

    const PaymentTiers = () => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderWidth: 1,
                borderRadius: 10,
                borderColor: theme.colors.border,
                padding: 5,
            }}
        >
            {props.paywallVariant === 'cost-estimation' ? (
                <CheckableFeature
                    header={'Pro'}
                    price={'$9.99/month'}
                    body={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop: 10,
                            }}
                        >
                            <BulletPoint text={`Unlimited project estimates`} />
                            <BulletPoint
                                text={`Spreadsheet download for easy editing`}
                            />
                            <BulletPoint
                                text={`Links for materials at cheapest live & local price`}
                            />
                            <BulletPoint
                                text={`Instant construction project plans`}
                            />
                        </div>
                    }
                    selected={true}
                    style={{ marginBottom: 5 }}
                    onPress={() => {}}
                />
            ) : props.paywallVariant === 'voice-ai' ? (
                <CheckableFeature
                    header={'Business'}
                    price={'$4.99/month'}
                    body={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop: 10,
                            }}
                        >
                            <BulletPoint
                                text={`Personalized AI Receptionist`}
                            />
                            <BulletPoint
                                text={`Busy and unanswered call forwarding`}
                            />
                            <BulletPoint
                                text={`Text summary of receptionist calls`}
                            />
                            <BulletPoint text={`Call recording and playback`} />
                            <BulletPoint text={`Spam detection`} />
                        </div>
                    }
                    selected={true}
                    style={{ marginBottom: 5 }}
                    onPress={() => {}}
                />
            ) : (
                <>
                    <CheckableFeature
                        header={'Personal'}
                        price={'$29.99/year'}
                        body={'Digitize paper with unlimited scans & storage'}
                        selected={!taxModuleSelected}
                        style={{ marginBottom: 5 }}
                        onPress={() => {
                            if (taxModuleSelected === true) {
                                setTaxModuleSelected(!taxModuleSelected);
                                onWebAppToggleTaxAddOn({
                                    value: !taxModuleSelected,
                                    cohort: '',
                                });
                            }
                        }}
                    />
                    <CheckableFeature
                        header={
                            makeProTierPersonalAndBusiness
                                ? 'Personal + Business'
                                : 'Business'
                        }
                        price={'$89.99/year'}
                        body={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingTop: 10,
                                }}
                            >
                                {!makeProTierPersonalAndBusiness ? (
                                    <BulletPoint
                                        text={`Everything in Personal`}
                                    />
                                ) : null}
                                <BulletPoint
                                    text={`Track expenses by category`}
                                />
                                <BulletPoint
                                    text={`Export itemized expenses`}
                                />
                                <BulletPoint
                                    text={`Use across multiple devices`}
                                />
                            </div>
                        }
                        selected={taxModuleSelected}
                        onPress={() => {
                            if (!taxModuleSelected) {
                                setTaxModuleSelected((t) => !t);
                                onWebAppToggleTaxAddOn({
                                    value: !taxModuleSelected,
                                    cohort: '',
                                });
                            }
                        }}
                    />
                </>
            )}
        </div>
    );
    return (
        <OnboardingCardTemplate
            style={{ position: 'relative' }}
            title={
                props.paywallVariant === 'cost-estimation'
                    ? `Get Started`
                    : `Start free trial`
            }
            subtitle={`Confirm your plan`}
            width={'100%'}
            content={
                <>
                    {authLoading &&
                    props.paywallVariant !== 'voice-ai' &&
                    props.paywallVariant !== 'cost-estimation' ? (
                        <div
                            style={{
                                padding: 20,
                            }}
                        >
                            <CircularProgress size={35} color="primary" />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: -10,
                            }}
                        >
                            {props.paywallVariant === 'voice-ai' ? null : (
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        top: 10,
                                        right: '3%',
                                        width: 30,
                                        height: 30,
                                    }}
                                    onClick={_onPressFreemiumX}
                                >
                                    <Close
                                        style={{
                                            width: 30,
                                            height: 30,
                                            color: '#000000',
                                            opacity: 0.5,
                                        }}
                                    />
                                </IconButton>
                            )}
                            <img
                                src={AppRating}
                                alt=""
                                height={80}
                                width={150}
                                style={{ marginBottom: 20 }}
                            />
                            <PaymentTiers />
                            <Body
                                style={{
                                    fontSize: 14,
                                    color: theme.colors.grayText,
                                    marginTop: 10,
                                }}
                            >
                                {props.paywallVariant === 'cost-estimation'
                                    ? ``
                                    : props.paywallVariant === 'voice-ai'
                                    ? `7 days free, then $4.99/month`
                                    : `7 days free, then ${
                                          taxModuleSelected
                                              ? PAYMENT_PRICE_PRO
                                              : PAYMENT_PRICE_BASE
                                      }`}
                            </Body>
                        </div>
                    )}
                </>
            }
            buttons={[
                {
                    text:
                        props.paywallVariant === 'cost-estimation'
                            ? 'Pay Now'
                            : 'Start Free Trial',
                    onClick: onClickNext,
                },
            ]}
        />
    );
};

export default PaymentTiers;
