import CostEstimatorWand from '../../../assets/animations/svGator/CostEstimatorWand';
import Survey, { ISurveyChoice } from '../../../components/Survey';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { onWebAppCostEstimationUserType } from '../../../tracking/trackers';
import { useCostEstimator } from './CostEstimatorProvider';

const SurveyStep = ({ userTypeList }: { userTypeList: ISurveyChoice[] }) => {
    const { width } = useWindowDimensions();
    const { prompt, userType, setUserType, draftingText, generateQuestions } =
        useCostEstimator();

    return (
        <>
            {!prompt && userType ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 10,
                        justifyContent: 'center',
                        width: Math.min(width * 0.8, 400),
                    }}
                >
                    <div style={{ fontSize: 25 }}>{draftingText}</div>
                    <CostEstimatorWand />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            fontSize: 22,
                            marginTop: 30,
                            fontWeight: '700',
                            width: 300,
                        }}
                    >
                        What best describes you?
                    </div>
                    <Survey
                        choices={[
                            ...userTypeList,
                            {
                                text: 'Other',
                                value: 'other',
                            },
                        ]}
                        style={{ width: 300 }}
                        onSelect={async (choice) => {
                            setUserType(choice);
                            onWebAppCostEstimationUserType({
                                userType: choice,
                            });
                            await generateQuestions();
                        }}
                    />
                    <div
                        style={{
                            fontSize: 15,
                            color: '#888',
                        }}
                    >
                        We'll use this to personalize your estimate
                    </div>
                </>
            )}
        </>
    );
};

export default SurveyStep;
