import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CallToActions } from '../../../../components/LandingPageVariations/configuration/types';
import { onWebAppCostEstimationStep } from '../../../../tracking/trackers';
import { callClaude } from './service';
import { ICELineItem } from './types';
import {
    ESTIMATE_PROMPT_SMALL,
    FURTHER_QUESTIONS_PROMPT,
    PROJECT_CLASSIFICATION_PROMPT,
    ESTIMATE_PROMPT_SMALL_DESCRIPTION_GENERATOR,
    ESTIMATE_PROMPT_SINGLE,
} from './prompts';

export const getLineItemAmount = (
    lineItem: ICELineItem,
    lineItems: ICELineItem[]
) => {
    if (lineItem.amount_type === 'percentage') {
        const total = lineItems.reduce((acc: number, curr: ICELineItem) => {
            if (curr.amount_type !== 'percentage')
                return (
                    acc +
                    (parseFloat(curr.unitprice || '0') || 0) *
                        (parseFloat(curr.quantity || '0') || 0)
                );
            return acc;
        }, 0);
        return (parseFloat(lineItem.unitprice || '0') / 100) * total;
    }
    return (
        (parseFloat(lineItem.unitprice || '0') || 0) *
        (parseFloat(lineItem.quantity || '0') || 0)
    );
};

const CostEstimatorContext = createContext<{
    prompt: string;
    setPrompt: React.Dispatch<React.SetStateAction<string>>;
    questionsToAnswer: { question: string; choices: string[] }[];
    setQuestionsToAnswer: React.Dispatch<
        React.SetStateAction<{ question: string; choices: string[] }[]>
    >;
    draftingText: string;
    setDraftingText: (text: string) => void;
    currentQuestionIndex: number;
    setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
    step: number;
    setStep: (step: number) => void;
    imageId: string | undefined;
    setImageId: (id: string | undefined) => void;
    imageUri: string | undefined;
    setImageUri: (uri: string | undefined) => void;
    generateQuestions: () => Promise<void>;
    ctaAction: CallToActions;
    isAddMarkup: boolean;
    setIsAddMarkup: (value: boolean) => void;
    setQuestionLoading: (loading: boolean) => void;
    newInstruction: string;
    setNewInstruction: React.Dispatch<React.SetStateAction<string>>;
    initialQuestionCount: number;
    showPaywallModal: boolean;
    setShowPaywallModal: (show: boolean) => void;
    showFeedbackModal: boolean;
    setShowFeedbackModal: (show: boolean) => void;
    questionLoading: boolean;
    gcDescriptions: any | undefined;
    setGcDescriptions: (descriptions: any) => void;
    reset: () => void;
    userType: string | undefined;
    setUserType: (userType: string | undefined) => void;
    messageHistory: any[];
    setMessageHistory: (messages: any[]) => void;
    classifyProject: (answers: string) => Promise<string>;
    setDrafting: (loading: boolean) => void;
    drafting: boolean;
    generateSmallEstimate: (
        answers: string,
        estimateName: string
    ) => Promise<void>;
    generateEstimateSingle: (answers: string) => Promise<void>;
    generateEstimate: () => Promise<void>;
    lineItems: ICELineItem[];
    setLineItems: (lineItems: ICELineItem[]) => void;
    wasTradesEdited: boolean;
    setWasTradesEdited: (wasTradesEdited: boolean) => void;
    estimateId: string | undefined;
}>({
    prompt: '',
    setPrompt: () => {},
    step: 0,
    setStep: () => {},
    questionsToAnswer: [],
    setQuestionsToAnswer: () => {},
    draftingText: '',
    setDraftingText: () => {},
    currentQuestionIndex: 0,
    setCurrentQuestionIndex: () => {},
    imageId: undefined,
    setImageId: () => {},
    generateQuestions: async () => {},
    ctaAction: 'CostEstimatorSignUp' as CallToActions,
    isAddMarkup: false,
    setIsAddMarkup: () => {},
    setQuestionLoading: () => {},
    newInstruction: '',
    setNewInstruction: () => {},
    initialQuestionCount: 0,
    showPaywallModal: false,
    setShowPaywallModal: () => {},
    showFeedbackModal: false,
    setShowFeedbackModal: () => {},
    questionLoading: false,
    imageUri: '',
    setImageUri: () => {},
    gcDescriptions: [],
    setGcDescriptions: () => {},
    reset: () => {},
    userType: '',
    setUserType: () => {},
    messageHistory: [],
    setMessageHistory: () => {},
    classifyProject: () => Promise.resolve(''),
    setDrafting: () => {},
    drafting: false,
    generateSmallEstimate: () => Promise.resolve(),
    generateEstimateSingle: () => Promise.resolve(),
    generateEstimate: () => Promise.resolve(),
    lineItems: [],
    setLineItems: () => {},
    wasTradesEdited: false,
    setWasTradesEdited: () => {},
    estimateId: undefined,
});

export const CostEstimatorProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [prompt, setPrompt] = useState('');
    const [step, setStep] = useState(0);
    // const [knowledgeBase, setKnowledgeBase] = useState<any>(undefined);
    const [questionsToAnswer, setQuestionsToAnswer] = useState<
        { question: string; choices: string[] }[]
    >([]);
    const [imageId, setImageId] = useState<string | undefined>(undefined);
    const [imageUri, setImageUri] = useState<string | undefined>(undefined);
    const [userType, setUserType] = useState<string | undefined>(undefined);
    const [isAddMarkup, setIsAddMarkup] = useState(false);
    const [showPaywallModal, setShowPaywallModal] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [questionLoading, setQuestionLoading] = useState(false);
    const [estimateId, setEstimateId] = useState<string>();
    const [newInstruction, setNewInstruction] = useState('');
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [messageHistory, setMessageHistory] = useState<any[]>([]);
    const [gcDescriptions, setGcDescriptions] = useState<any>(undefined);
    const [lineItemsClone, setLineItemsClone] = useState<ICELineItem[]>([]);
    const [initialQuestionCount, setInitialQuestionCount] = useState<number>(0);
    const [drafting, setDrafting] = useState<boolean>(false);
    const [draftingText, setDraftingText] = useState<string>(
        'Gathering information...'
    );
    const [lastMessages, setLastMessages] = useState<any[]>([]);
    const [lineItems, setLineItems] = useState<ICELineItem[]>([]);
    const [wasTradesEdited, setWasTradesEdited] = useState<boolean>(false);

    useEffect(() => {
        setEstimateId(uuidv4());
    }, []);

    const reset = () => {
        setImageId(undefined);
        setImageUri(undefined);
        setCurrentQuestionIndex(0);
        setQuestionsToAnswer([]);
        setStep(0);
        setPrompt('');
        setMessageHistory([]);
        setGcDescriptions(undefined);
        setLineItems([]);
        setWasTradesEdited(false);
        setLineItemsClone([]);
    };

    const knowledgebasePromptAddition = '';

    const generateQuestions = async () => {
        setQuestionLoading(true);
        setCurrentQuestionIndex(0);
        onWebAppCostEstimationStep({
            step: 'generating-questions',
            estimate_id: estimateId || '',
        });
        try {
            const { response, messages } = await callClaude({
                instructions:
                    FURTHER_QUESTIONS_PROMPT + knowledgebasePromptAddition,
                prompt,
                imageUri,
            });

            setMessageHistory(messages);
            const questions = JSON.parse(
                response.split('<questions>')[1].split('</questions>')[0]
            ).questions;
            setQuestionsToAnswer(questions);
            setInitialQuestionCount(questions.length);
            onWebAppCostEstimationStep({
                step: 'generated-questions',
                estimate_id: estimateId || '',
                metadata: {
                    questions: questions,
                    question_count: questions.length,
                },
            });
        } catch (error) {
            console.error('Error generating questions:', error);
        } finally {
            setQuestionLoading(false);
        }
    };

    const classifyProject = async (answers: string) => {
        const { response } = await callClaude({
            instructions: PROJECT_CLASSIFICATION_PROMPT,
            prompt: `Project Description: ${prompt}\n\nAdditional Answers:\n ${answers}`,
        });
        const classification = JSON.parse(
            response.split('<classification>')[1].split('</classification>')[0]
        ).class;
        onWebAppCostEstimationStep({
            estimate_id: estimateId || '',
            step: 'classified-project',
            metadata: {
                prompt: prompt,
                classification: classification,
            },
        });
        return classification;
    };

    const generateSmallEstimate = async (
        answers: string,
        estimateName: string
    ) => {
        const { response } = await callClaude({
            instructions: ESTIMATE_PROMPT_SMALL + knowledgebasePromptAddition,
            prompt: `Project Description: ${prompt}\n\nAdditional Answers:\n ${answers}`,
            imageUri,
        });

        const e = JSON.parse(
            response.split('<line_items>')[1].split('</line_items>')[0]
        ).estimate;

        const compactLineItems = JSON.parse(
            response.split('<line_items>')[1].split('</line_items>')[0]
        ).estimate;

        compactLineItems.materials = compactLineItems.materials.map(
            (m: any) => ({
                name: m.name,
                description: m.description,
                quantity: m.quantity,
                units: m.units,
                unitprice: m.unitprice,
            })
        );

        const _gcDescriptions = await callClaude({
            instructions: ESTIMATE_PROMPT_SMALL_DESCRIPTION_GENERATOR,
            prompt: `<project_description>${prompt}</project_description>\n\n<estimate>${JSON.stringify(
                compactLineItems
            )}</estimate>`,
        });

        setGcDescriptions({
            trades: [
                {
                    name: estimateName,
                    section_title: estimateName,
                    description: _gcDescriptions.response
                        .replaceAll('•', '\n-')
                        .split('\n')
                        .filter((l) => l.trim() !== '')
                        .join('\n'),
                },
            ],
            measurements: {},
            source: 'generateSmallEstimate',
        });
        setDrafting(false);

        setLineItemsClone((prev) =>
            [
                ...prev,
                ...e.materials.map((m: any) => ({
                    ...m,
                    trade: estimateName,
                    labor_trade: estimateName,
                    group: 'material',
                    amount_type: 'fixed',
                    unit: m.units,
                })),
                ...[e.labor].map((l: any) => ({
                    ...l,
                    trade: estimateName,
                    labor_trade: estimateName,
                    name: 'Labor',
                    group: 'labor',
                    amount_type: 'fixed',
                    unit: e.labor.unit,
                    unitprice: e.labor.unitprice,
                })),
            ].map((l) => ({ ...l, quantity: l.quantity || '0' }))
        );
    };

    const generateEstimateSingle = async (answers: string) => {
        onWebAppCostEstimationStep({
            step: 'generating-estimate',
            estimate_id: estimateId!,
        });
        const { response, messages } = await callClaude({
            instructions: ESTIMATE_PROMPT_SINGLE + knowledgebasePromptAddition,
            prompt: `Project Description: ${prompt}\n\nAdditional Answers:\n ${answers}`,
            imageUri,
        });
        setLastMessages(messages);
        const trades = JSON.parse(
            response.split('<trades>')[1].split('</trades>')[0]
        ).trades;

        setGcDescriptions({
            trades: trades.map((t: any) => ({
                name: t.trade_name,
                section_title: t.section_title,
                description: t.project_description
                    .replaceAll('•', '-')
                    .replaceAll('-', '\n')
                    .split('\n')
                    .filter((l: string) => l.trim() !== '')
                    .join('\n'),
            })),
            measurements: {},
        });

        const e = JSON.parse(
            response.split('<line_items>')[1].split('</line_items>')[0]
        ).estimate;

        setDrafting(false);

        setLineItemsClone((prev) =>
            [
                ...prev,
                ...e.materials.map((m: any) => ({
                    ...m,
                    trade: m.trade,
                    labor_trade: m.trade,
                    group: 'material',
                    amount_type: 'fixed',
                    unit: m.units,
                })),
                ...e.labors.map((l: any) => ({
                    ...l,
                    trade: l.trade,
                    labor_trade: l.trade,
                    name: 'Labor',
                    group: 'labor',
                    amount_type: 'fixed',
                    unit: l.unit,
                    unitprice: l.unitprice,
                })),
            ].map((l) => ({ ...l, quantity: l.quantity || '0' }))
        );
    };

    const generateEstimate = async (_gcDescriptions?: string) => {
        onWebAppCostEstimationStep({
            step: 'generating-estimate',
            estimate_id: estimateId!,
        });
        if (wasTradesEdited) {
            setDrafting(true);
            setDraftingText('Optimizing Estimate...');
            const { response } = await callClaude({
                instructions:
                    ESTIMATE_PROMPT_SINGLE + knowledgebasePromptAddition,
                prompt: `<trades>${JSON.stringify(
                    gcDescriptions.trades.map((t: any) => ({
                        trade_name: t.name,
                        section_title: t.section_title,
                        project_description: t.description,
                    }))
                )}</trades>\n\n<line_items>`,
                isPartial: true,
                messages: lastMessages.slice(0, lastMessages.length - 1),
            });
            const e = JSON.parse(response.split('</line_items>')[0]).estimate;
            setLineItems((prev) =>
                [
                    ...(prev || []),
                    ...e.materials.map((m: any) => ({
                        ...m,
                        trade: m.trade,
                        labor_trade: m.trade,
                        group: 'material',
                        amount_type: 'fixed',
                        unit: m.units,
                    })),
                    ...e.labors.map((l: any) => ({
                        ...l,
                        trade: l.trade,
                        labor_trade: l.trade,
                        name: 'Labor',
                        group: 'labor',
                        amount_type: 'fixed',
                        unit: l.unit,
                        unitprice: l.unitprice,
                    })),
                ].map((l) => ({ ...l, quantity: l.quantity || '0' }))
            );
        } else {
            const remainedTrades = gcDescriptions.trades.map(
                (t: any) => t.name
            );
            setLineItems(
                lineItemsClone.filter((l) => remainedTrades.includes(l.trade))
            );
        }

        onEstimateGenerated();
    };

    const onEstimateGenerated = () => {
        setLineItems((prev) => {
            const newLineItems = [
                ...prev,
                {
                    name: 'Markup',
                    quantity: '1',
                    group: 'other',
                    trade: 'Other',
                    amount_type: 'percentage' as any,
                    unitprice: '10',
                    description: '',
                },
                {
                    name: 'Tax',
                    quantity: '1',
                    group: 'other',
                    trade: 'Other',
                    amount_type: 'percentage' as any,
                    unitprice: '0',
                    description: '',
                },
            ];
            return newLineItems;
        });
        setDrafting(false);
    };

    const ctaAction = 'CostEstimatorSignUp' as const;

    const value = useMemo(
        () => ({
            prompt,
            setPrompt,
            step,
            setStep,
            questionsToAnswer,
            setQuestionsToAnswer,
            imageId,
            setImageId,
            imageUri,
            setImageUri,
            generateQuestions,
            ctaAction,
            isAddMarkup,
            setIsAddMarkup,
            setQuestionLoading,
            setNewInstruction,
            initialQuestionCount,
            showPaywallModal,
            setShowPaywallModal,
            showFeedbackModal,
            setShowFeedbackModal,
            questionLoading,
            gcDescriptions,
            setGcDescriptions,
            reset,
            draftingText,
            setDraftingText,
            currentQuestionIndex,
            setCurrentQuestionIndex,
            userType,
            setUserType,
            messageHistory,
            setMessageHistory,
            drafting,
            setDrafting,
            classifyProject,
            generateSmallEstimate,
            generateEstimateSingle,
            generateEstimate,
            lineItems,
            setLineItems,
            wasTradesEdited,
            setWasTradesEdited,
            lineItemsClone,
            setLineItemsClone,
            estimateId,
            newInstruction,
        }),
        [
            prompt,
            step,
            questionsToAnswer,
            imageId,
            imageUri,
            setImageUri,
            isAddMarkup,
            showPaywallModal,
            showFeedbackModal,
            questionLoading,
            gcDescriptions,
            draftingText,
            currentQuestionIndex,
            userType,
            messageHistory,
            setMessageHistory,
            initialQuestionCount,
            drafting,
            lineItems,
            wasTradesEdited,
            lineItemsClone,
            estimateId,
            newInstruction,
        ]
    );

    return (
        <CostEstimatorContext.Provider value={value}>
            {children}
        </CostEstimatorContext.Provider>
    );
};

export const useCostEstimator = () => useContext(CostEstimatorContext);
