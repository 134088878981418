import { useState } from 'react';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/auth/service';
import {
    onWebAppSubmitPasswordFailure,
    onWebAppSubmitPasswordSuccess,
} from '../../../tracking/trackers';
import * as Validators from '../../../util/validators';
import { useAuth } from '../../../providers/AuthProvider';
import { useQuery } from '../../../util/hooks';

const useSetPassword = (
    mobile: string | undefined,
    code: string | undefined
) => {
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const query = useQuery();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [passwordIsSet, setPasswordIsSet] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const variant = query.get('variant');
    const method = query.get('method');
    const couponApplied = query.get('couponApplied');
    const isCostEstimatorPaywall = variant === 'cost-estimator';

    // sets the token in local storage to make requests as an authenticated user
    const setPassword = (password: string | undefined) => {
        if (!mobile || !code) {
            console.error('mobile or code not set');
            return;
        }
        setIsLoading(true);
        setErrorMessage('');
        const formattedMobile =
            parsePhoneNumberFromString(mobile, 'US')?.format(
                Validators.US_PHONE
            ) || '';
        AuthService.setPassword(formattedMobile, code, password)
            .then((res) => {
                if (res.token) {
                    setToken(res.token);
                    onWebAppSubmitPasswordSuccess({ mobile: formattedMobile });
                    if (isCostEstimatorPaywall) {
                        navigate(
                            `/cost-estimator?subscription-success=true&method=${method}&couponApplied=${couponApplied}`
                        );
                        return;
                    }
                    setPasswordIsSet(true);
                    setIsLoading(false);
                    return;
                }
                AuthService.getToken(formattedMobile, password!)
                    .then(({ token }) => {
                        setToken(token);
                        // previously, we didn't await getting the token,
                        // but when users are doing the /payment route with sign up, setPasswordIsSet(true) triggers the payment page, and the payment page requires a token
                        if (res.status === 'success') {
                            onWebAppSubmitPasswordSuccess({
                                mobile: formattedMobile,
                            });
                            if (isCostEstimatorPaywall) {
                                navigate(
                                    `/cost-estimator?subscription-success=true&method=${method}&couponApplied=${couponApplied}`
                                );
                                return;
                            }
                            setPasswordIsSet(true);
                        }
                    })
                    .finally(() => setIsLoading(false));
            })
            .catch((err) => {
                onWebAppSubmitPasswordFailure({ mobile: formattedMobile });
                console.error('error in sendSignupLink:', err);
                setErrorMessage('Failed to set password');
                setIsLoading(false);
            });
    };

    return { isLoading, passwordIsSet, errorMessage, setPassword };
};

export default useSetPassword;
