import Resizer from 'react-image-file-resizer';

function cleanJSONString(jsonString: string) {
    // eslint-disable-next-line no-control-regex
    return jsonString.replace(/[\u0000-\u001F]/g, '');
}
const resizeImage = async (blob: Blob): Promise<string | undefined> => {
    try {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                blob,
                2048, // Using Claude's recommended size
                2048,
                'JPEG',
                75, // Using Claude's recommended quality
                0,
                (
                    resizedBlob:
                        | string
                        | Blob
                        | File
                        | ProgressEvent<FileReader>
                ) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result as string;
                        resolve(base64String.split(',')[1]);
                    };
                    reader.readAsDataURL(resizedBlob as Blob);
                },
                'blob'
            );
        });
    } catch (error) {
        console.error('Error resizing image:', error);
        return undefined;
    }
};

export const callClaude = async ({
    prompt,
    instructions,
    imageUri,
    quick = false,
    messages = [],
    isPartial = false,
}: {
    prompt?: string;
    instructions?: string;
    imageUri?: string;
    quick?: boolean;
    messages?: any;
    isPartial?: boolean;
}) => {
    let base64Image;
    if (imageUri) {
        const response = await fetch(imageUri);
        const blob = await response.blob();
        base64Image = await resizeImage(blob);
    }
    const messagesToSend = [
        ...messages,
        {
            role: isPartial ? 'assistant' : 'user',
            content: [
                ...(base64Image
                    ? [
                          {
                              type: 'image',
                              source: {
                                  type: 'base64',
                                  media_type: imageUri?.endsWith('.png')
                                      ? 'image/png'
                                      : 'image/jpeg',
                                  data: base64Image,
                              },
                          },
                      ]
                    : []),
                {
                    type: 'text',
                    text: prompt,
                },
            ],
        },
    ];

    let response = '';
    let previousTimeout = null;
    for (let i = 0; i < 2; i++) {
        try {
            if (previousTimeout) clearTimeout(previousTimeout);
            const controller = new AbortController();
            previousTimeout = setTimeout(
                () => controller.abort(),
                quick ? 24000 : 30000
            );
            const res = await fetch(
                'https://api.simplywise.com/api/v2/public/call-claude',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        body: {
                            model: !quick
                                ? 'claude-3-5-sonnet-20241022'
                                : 'claude-3-5-haiku-20241022',
                            temperature: 0.2,
                            max_tokens: 5000,
                            system: instructions,
                            messages: messagesToSend,
                        },
                    }),
                    signal: controller.signal,
                }
            );
            response = cleanJSONString(
                (await res.json()).data.output.content[0].text
            );
            break;
        } catch (e) {
            console.log('error', e);
        }
    }

    return {
        response,
        messages: [
            ...messagesToSend,
            {
                role: 'assistant',
                content: [
                    {
                        type: 'text',
                        text: response,
                    },
                ],
            },
        ],
    };
};
