import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useAuth } from '../../providers/AuthProvider';
import {
    onWebAppVoiceAIFindCarrierByTwillio,
    onWebAppVoiceAIFindCarrierByTwillioFailed,
} from '../../tracking/trackers';
import {
    ReceoptionistMetadata,
    ReceptionistCall,
    ReceptionistPersonality,
    ReceptionistPersonalityMetadata,
} from './types';
import USSDs from './USSDs.json';
import ReceptionistService from '.';

export type Carrier = keyof typeof USSDs;

export interface IReceptionistState {
    receptionist?: ReceoptionistMetadata;
    getReceptionist: () => void;
    updateReceptionist: (metadata: ReceoptionistMetadata) => void;
    calls: ReceptionistCall[];
    getCallsHistory: () => void;
    carrier?: Carrier;
    getUSSDs: (carrier: Carrier) => Record<string, string> | undefined;
}

const ReceptionistContext = createContext<IReceptionistState>({
    receptionist: {},
    updateReceptionist: () => {},
    getReceptionist: () => {},
    calls: [],
    getCallsHistory: () => {},
    carrier: undefined,
    getUSSDs: () => ({}),
});

// const RECEPTIONST_CACHE_KEY = 'receptionist';
// const RECEPTIONST_CALLS_CACHE_KEY = 'receptionist_calls';
// const CARRIER_CACHE_KEY = 'carrier';

const FORWARDING_NUMBER = '5175102015';
// const FORWARDING_NUMBER = '2059463807';

export const PERSONALITIES: {
    [key in Partial<ReceptionistPersonality>]: ReceptionistPersonalityMetadata;
} = {
    diane: {
        name: 'Diane',
        instructions: 'Calm, soothing presence.',
        picture: require('../../assets/leafWhite.png'),
        voice: 'alloy',
    },
    max: {
        name: 'Max',
        instructions: 'Bright and energetic.',
        picture: require('../../assets/leafWhite.png'),
        voice: 'shimmer',
    },
    nora: {
        name: 'Nora',
        instructions: 'Polite and organized.',
        picture: require('../../assets/leafWhite.png'),
        voice: 'coral',
    },
    leo: {
        name: 'Leo',
        instructions: 'Friendly and welcoming.',
        picture: require('../../assets/leafWhite.png'),
        voice: 'ballad',
    },
    ava: {
        name: 'Ava',
        instructions: 'Clear and tech-savvy.',
        picture: require('../../assets/leafWhite.png'),
        voice: 'echo',
    },
    alex: {
        name: 'Alex',
        instructions: 'Direct and efficient.',
        picture: require('../../assets/leafWhite.png'),
        voice: 'shimmer',
    },
    mia: {
        name: 'Mia',
        instructions: 'Relaxing and reassuring.',
        picture: require('../../assets/leafWhite.png'),
        voice: 'verse',
    },
    ben: {
        name: 'Ben',
        instructions: 'Quick and concise.',
        picture: require('../../assets/leafWhite.png'),
        voice: 'ash',
    },
};

export const ReceptionistProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const { user, token } = useAuth();
    // NOTE: Use the original receptinionist object to support multiple numbers
    const [receptionist, setReceptionist] = useState<ReceoptionistMetadata>({});
    const [calls, setCalls] = useState<ReceptionistCall[]>([]);
    const [carrier, setCarrier] = useState<Carrier | undefined>(undefined);

    useEffect(() => {
        if (token && user?.mobile)
            ReceptionistService.createReceptionist({
                token,
                mobile: user?.mobile,
                metadata: {
                    name: user?.first_name,
                },
            })
                .then(() => {
                    getReceptionist();
                })
                .catch((error) => {
                    console.log('Error creating receptionist', error);
                });
    }, [user?.mobile, token]);

    const getReceptionist = () => {
        if (!token || !user?.mobile) return;
        ReceptionistService.getReceptionist({
            token,
            mobile: user?.mobile!,
        }).then((_receptionist) => {
            setReceptionist({
                personality:
                    _receptionist.data?.metadata?.personality || 'diane',
                ..._receptionist.data?.metadata,
            });
        });
    };

    const getCallsHistory = () => {
        if (!token || !user?.mobile) return;
        let resolver: (value: unknown) => void;
        const promise = new Promise((_resolver) => (resolver = _resolver));
        ReceptionistService.getReceptionistCalls({
            token,
            mobile: user?.mobile!,
        }).then((_calls) => {
            resolver(_calls.data);
            setCalls(_calls.data);
        });
        return promise;
    };

    const findCarrier = async () => {
        let twilioCarrier: string | undefined;

        const twilioCarrierResponse = await ReceptionistService.findCarrier({
            token: token!,
            mobile: user?.mobile!,
        });
        twilioCarrier = twilioCarrierResponse.data?.carrier;

        if (!twilioCarrier) {
            onWebAppVoiceAIFindCarrierByTwillioFailed();
        } else {
            onWebAppVoiceAIFindCarrierByTwillio({
                carrier: twilioCarrier,
            });
            if (!USSDs[twilioCarrier as Carrier]) return;
            setCarrier(twilioCarrier as Carrier);
        }
    };

    useEffect(() => {
        if (token && user?.mobile) {
            findCarrier();
        }
    }, [token, user?.mobile]);

    useEffect(() => {
        getReceptionist();
        getCallsHistory();
    }, [user?.mobile]);

    useEffect(() => {
        const interval = setInterval(() => {
            getCallsHistory();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const updateReceptionist = (metadata: ReceoptionistMetadata) => {
        if (token && user?.mobile)
            ReceptionistService.updateReceptionist({
                token,
                mobile: user?.mobile,
                metadata,
            })
                .then(() => {
                    setReceptionist((_m) => ({ ..._m, ...metadata }));
                })
                .catch((error) => {
                    console.error('Error updating receptionist', error);
                });
    };

    const getUSSDs = (_carrier: Carrier) => {
        _carrier = _carrier?.toLowerCase() as Carrier;
        const ussds = USSDs?.[_carrier as Carrier];
        if (!ussds) return undefined;
        setCarrier(_carrier);

        // replace <mobile> in each ussds property with the user's mobile number
        return Object.keys(ussds).reduce((acc, key) => {
            const cleanedMobile = FORWARDING_NUMBER;
            // @ts-ignore
            const cleanedUSSD = ussds?.[key];
            if (!cleanedMobile || !cleanedUSSD) return acc;
            acc[key] = cleanedUSSD.replace('<mobile>', cleanedMobile);
            return acc;
        }, {} as Record<string, string>);
    };

    return (
        <ReceptionistContext.Provider
            value={{
                receptionist,
                getReceptionist,
                updateReceptionist,
                calls,
                getCallsHistory,
                carrier,
                getUSSDs,
            }}
        >
            {children}
        </ReceptionistContext.Provider>
    );
};

export const useReceptionist = () => useContext(ReceptionistContext);

export default ReceptionistContext;
