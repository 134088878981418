import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import { IStandardResponse } from '../../types/API';
import {
    ISetSubscriptionResponse,
    IStripeCreateSubscriptionResponse,
    ISubscriptionBillingEventsResponse,
} from './types';

export default class SubscriptionService {
    public static async setSubscription(
        order_id: string | null,
        product_id: string | null,
        original_order_id?: string,
        metadata?: {
            displayOrderId?: string;
        },
        shouldSendTrialReminder?: boolean
    ): Promise<ISetSubscriptionResponse> {
        return axios
            .post('/subscriptions/set', {
                subscription_tier: product_id,
                order_id,
                original_order_id,
                platform: 'web',
                metadata,
                should_send_trial_reminder: shouldSendTrialReminder,
            })
            .then((value: AxiosResponse<ISetSubscriptionResponse>) => {
                return value.data;
            });
    }

    public static async stripeSetSubscription(
        productId: string,
        quantity?: number,
        couponId?: string,
        order_id?: string
    ): Promise<IStripeCreateSubscriptionResponse> {
        return axios
            .post(`/subscriptions/stripe/subscriptions/set`, {
                product_id: productId,
                quantity: quantity || 1,
                coupon: couponId,
                order_id,
                source: 'web',
            })
            .then((value) => {
                return value.data;
            });
    }

    public static async stripeApplyCoupon(
        subscriptionId: string,
        couponId: string
    ): Promise<IStandardResponse> {
        return axios
            .post(`/subscriptions/stripe/subscriptions/apply-coupon`, {
                subscription_id: subscriptionId,
                coupon: couponId,
            })
            .then((value) => {
                return value.data;
            });
    }

    public static stripeUpdateSubscriptionQuantity(
        txId: string,
        quantity: number
    ): Promise<IStandardResponse> {
        return axios
            .post('/subscriptions/stripe/subscriptions/update/quantity', {
                tx_id: txId,
                quantity: Number(quantity),
            })
            .then((value: AxiosResponse<IStandardResponse>) => value.data);
    }

    public static stripeCancelSubscription(
        txId: string
    ): Promise<IStandardResponse> {
        return axios
            .post('/subscriptions/stripe/subscriptions/delete', {
                tx_id: txId,
            })
            .then((value: AxiosResponse<IStandardResponse>) => value.data);
    }

    public static async paypalSetSubscriptionUser(
        tier: 'base' | 'pro',
        subscription_id: string,
        mobile?: string
    ): Promise<IStandardResponse> {
        return axios
            .post('/subscriptions/paypal/subscription/set', {
                mobile,
                tier,
                subscription_id,
            })
            .then((value: AxiosResponse<IStandardResponse>) => {
                return value.data;
            });
    }

    public static async createFreemiumUser(): Promise<IStandardResponse> {
        return axios
            .post('/subscriptions/set', {
                subscription_tier:
                    'freemium_group_30and90_cohort_limited-scans',
                order_id: 'freemium',
                platform: 'stripe',
            })
            .then((value: AxiosResponse<IStandardResponse>) => {
                return value.data;
            });
    }

    public static async getBillingEvents(
        token: string,
        transactionId: string
    ): Promise<ISubscriptionBillingEventsResponse> {
        return axios
            .post(
                '/subscriptions/transactions/events/get',
                {
                    transaction_auto_id: transactionId,
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then(
                (value: AxiosResponse<ISubscriptionBillingEventsResponse>) => {
                    return value.data;
                }
            );
    }
}
