import { Button, Modal } from '@mui/material';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { useState } from 'react';
import { TouchableOpacity } from '../../../components/Touchables';
import theme from '../../../theme';
import {
    onWebAppCostEstimationFeedback,
    onWebAppCostEstimationClickPayment,
    onWebAppCostEstimationClickContinue,
    onWebAppCostEstimationAnswerQuestion,
} from '../../../tracking/trackers';
import PaymentTiers from '../../Onboarding/PaymentTiers';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import MeasuringTape from '../../../assets/animations/svGator/MeasuringTape';
import Survey from '../../../components/Survey';
import { useCTAAction } from '../../../components/LandingPageVariations/elements/useCTAAction';
import { CallToActions } from '../../../components/LandingPageVariations/configuration/types';
import AuthService from '../../../services/auth/service';
import { useAuth } from '../../../providers/AuthProvider';
import { STRIPE_PRICE_CE_BASE_10_MONTHLY } from '../../Onboarding/Payment/CheckoutForm';
import ProjectBreakdown from '../../../assets/animations/svGator/ProjectBreakdown';
import LineItemList from './LineItemList';
import { useCostEstimator } from './CostEstimatorProvider';
import TradeDescriptionList from './TradeDescriptionList';
import CustomAnswerModal from './CustomAnswerModal';
import DraftingAnimation from './DraftingAnimation';
import { ICELineItem } from './CostEstimatorProvider/types';

const FinalStep = ({
    onAnswerQuestion,
    showFeedbackModal,
    setShowFeedbackModal,
    showPaywallModal,
    setShowPaywallModal,
    downloadText,
    downloadApp,
    estimateTotal,
    ctaType,
    ctaAction,
}: {
    onAnswerQuestion: (c: string, isCustom: boolean) => void;
    showFeedbackModal: boolean;
    setShowFeedbackModal: (show: boolean) => void;
    showPaywallModal: boolean;
    setShowPaywallModal: (show: boolean) => void;
    downloadText: string[];
    downloadApp: () => void;
    estimateTotal: number;
    ctaType: string;
    ctaAction: CallToActions | undefined;
}) => {
    const {
        questionsToAnswer,
        initialQuestionCount,
        questionLoading,
        draftingText,
        setDraftingText,
        setDrafting,
        setQuestionsToAnswer,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        gcDescriptions,
        drafting,
        lineItems,
        wasTradesEdited,
    } = useCostEstimator();
    const { width } = useWindowDimensions();
    const { isSubscribed, user, setToken } = useAuth();
    const [showCustomAnswerModal, setShowCustomAnswerModal] = useState(false);
    const [isAddNewItem, setIsAddNewItem] = useState(false);
    const [editingLineItem, setEditingLineItem] = useState<
        (ICELineItem & { index: number }) | undefined
    >(undefined);

    const costEstimationSignUp = useCTAAction(ctaAction);

    const createAccountPhoneless = () => {
        // Create account without phone number
        AuthService.createAccountPhoneless().then((data) => {
            if (data?.data?.token) setToken(data?.data?.token);
            if (data?.data?.otp)
                localStorage.setItem('visitor_slug', data?.data?.otp);
            costEstimationSignUp();
        });
    };

    if (questionLoading) {
        setDraftingText('Gathering Information...');
        return (
            <>
                <DraftingAnimation
                    draftingText={draftingText}
                    animation={<MeasuringTape />}
                />
            </>
        );
    }

    if (questionsToAnswer.length > 0) {
        return (
            <div style={{ flex: 1 }}>
                <div
                    style={{
                        width: Math.min(width * 0.8, 600),
                        marginBottom: 20,
                    }}
                >
                    <div
                        style={{
                            height: 10,
                            backgroundColor: '#E0E0E0',
                            borderRadius: 5,
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: `${
                                    initialQuestionCount > 0
                                        ? (currentQuestionIndex /
                                              initialQuestionCount) *
                                          100
                                        : 0
                                }%`,
                                backgroundColor: theme.colors.primary400,
                                borderRadius: 5,
                            }}
                        />
                    </div>
                </div>

                <CustomAnswerModal
                    open={showCustomAnswerModal}
                    onClose={() => setShowCustomAnswerModal(false)}
                    onSubmit={(answer) => {
                        if (answer.trim()) {
                            if (
                                currentQuestionIndex >=
                                questionsToAnswer.length - 1
                            ) {
                                onAnswerQuestion(answer, true);
                                Promise.resolve().then(() => {
                                    setDraftingText('Gathering Information...');
                                    setDrafting(true);
                                    setQuestionsToAnswer([]);
                                });

                                return;
                            }

                            onAnswerQuestion(answer, true);
                            onWebAppCostEstimationAnswerQuestion({
                                question:
                                    questionsToAnswer[currentQuestionIndex]
                                        .question,
                                answer: answer,
                                is_custom_answer: true,
                            });
                            setCurrentQuestionIndex(
                                (prevIndex) => prevIndex + 1
                            );
                        }
                    }}
                />
                {/* Questions Survey step */}
                <>
                    <div
                        style={{
                            width: Math.min(width * 0.8, 600),
                            fontSize: width < 768 ? 18 : 25,
                        }}
                    >
                        {questionsToAnswer[currentQuestionIndex].question}
                    </div>
                    <div
                        style={{
                            width: Math.min(width * 0.8, 600),
                            marginTop: 20,
                            alignSelf: 'center',
                            flex: 1,
                            paddingBottom: 50,
                        }}
                    >
                        <Survey
                            key={currentQuestionIndex}
                            choices={[
                                ...questionsToAnswer[
                                    currentQuestionIndex
                                ].choices.map((c) => {
                                    return {
                                        text: c,
                                        value: c,
                                    };
                                }),
                                {
                                    text: 'Other',
                                    value: 'custom',
                                },
                            ]}
                            textStyle={{
                                textAlign: 'left',
                            }}
                            onSelect={(c) => {
                                if (c === 'custom') {
                                    setShowCustomAnswerModal(true);
                                } else {
                                    onWebAppCostEstimationAnswerQuestion({
                                        question:
                                            questionsToAnswer[
                                                currentQuestionIndex
                                            ].question,
                                        answer: c,
                                        is_custom_answer: false,
                                    });
                                    if (
                                        currentQuestionIndex >=
                                        questionsToAnswer.length - 1
                                    ) {
                                        onAnswerQuestion(c, false);
                                        Promise.resolve().then(() => {
                                            setDraftingText(
                                                'Create Project Breakdown...'
                                            );
                                            setDrafting(true);
                                            setQuestionsToAnswer([]);
                                        });

                                        return;
                                    }
                                    onAnswerQuestion(c, false);
                                    setCurrentQuestionIndex(
                                        (prevIndex) => prevIndex + 1
                                    );
                                }
                            }}
                        />
                    </div>
                </>
            </div>
        );
    }

    if (drafting && !wasTradesEdited) {
        return <DraftingAnimation animation={<ProjectBreakdown />} />;
    }

    if (gcDescriptions) {
        return <TradeDescriptionList />;
    }

    if (wasTradesEdited && drafting) {
        return (
            <DraftingAnimation
                draftingText={draftingText}
                animation={<MeasuringTape />}
            />
        );
    }

    if (lineItems.length > 0) {
        return (
            <>
                <LineItemList
                    estimateTotal={estimateTotal}
                    editingLineItem={editingLineItem}
                    setEditingLineItem={setEditingLineItem}
                    isAddNewItem={isAddNewItem}
                    setIsAddNewItem={setIsAddNewItem}
                />
                {!editingLineItem && !isAddNewItem && (
                    <div
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            width: '100%',
                            margin: '0 auto',
                            zIndex: 1000,
                            padding: `${width < 768 ? 10 : 15}px 16px`,
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: theme.colors.white,
                            boxShadow:
                                '0px -12px 24px -8px rgba(0, 0, 0, 0.03), 0px -4px 8px -4px rgba(0, 0, 0, 0.02)',
                            background:
                                'linear-gradient(180deg, rgba(255,255,255,0.85) 0%, rgba(255,255,255,0.95) 30%, rgba(255,255,255,1) 60%)',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={
                                ctaType === 'appstore' ||
                                (isSubscribed &&
                                    user?.subscription_tier ===
                                        STRIPE_PRICE_CE_BASE_10_MONTHLY)
                                    ? downloadApp
                                    : () => {
                                          // setShowPaywallModal(true);
                                          onWebAppCostEstimationClickContinue();
                                          createAccountPhoneless();
                                      }
                            }
                            style={{
                                color: 'white',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                borderRadius: 28,
                                height: 56,
                                width: '90%',
                                fontWeight: '600',
                                fontSize: 18,
                                textTransform: 'none',
                            }}
                        >
                            Continue
                        </Button>
                    </div>
                )}
                <Modal
                    open={showFeedbackModal}
                    onClose={() => setShowFeedbackModal(false)}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: theme.colors.white,
                            borderRadius: 10,
                            width: 300,
                            padding: 20,
                            fontWeight: '600',
                        }}
                    >
                        Was the estimate helpful?
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 60,
                                marginTop: 20,
                                marginBottom: 10,
                            }}
                        >
                            <TouchableOpacity
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 50,
                                    height: 50,
                                }}
                                onClick={() => {
                                    onWebAppCostEstimationFeedback({
                                        feedback: 'thumbs-down',
                                    });
                                    setShowFeedbackModal(false);
                                }}
                            >
                                <ThumbDown
                                    style={{
                                        color: theme.colors.secondary,
                                        width: 35,
                                        height: 35,
                                    }}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 50,
                                    height: 50,
                                }}
                                onClick={() => {
                                    onWebAppCostEstimationFeedback({
                                        feedback: 'thumbs-up',
                                    });
                                    setShowFeedbackModal(false);
                                }}
                            >
                                <ThumbUp
                                    style={{
                                        color: theme.colors.secondary,
                                        width: 35,
                                        height: 35,
                                    }}
                                />
                            </TouchableOpacity>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={showPaywallModal}
                    onClose={() => setShowPaywallModal(false)}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: theme.colors.white,
                            width: width * 0.9,
                            borderRadius: 10,
                            maxWidth: 500,
                            padding: 20,
                        }}
                    >
                        <PaymentTiers
                            goToPayment={() => {
                                onWebAppCostEstimationClickPayment();
                                // downloadApp();
                                costEstimationSignUp();
                            }}
                            onPressFreemiumX={() => {
                                setShowPaywallModal(false);
                            }}
                            paywallVariant="cost-estimation"
                        />
                    </div>
                </Modal>
                <div style={{ height: 50 }}></div>
            </>
        );
    }

    return null;
};

export default FinalStep;
