export const ALL_DOCUMENTS = 'All Documents';
export const ADD_FOLDER_FOLDER_ID = 'add-folder-user-unique';

export const PAYMENT_PRICE_AR_BASE = '$4.99/month';
export const PAYMENT_PRICE_CE_BASE = '$9.99/month';
export const PAYMENT_PRICE_BASE = '$29.99/year';
export const PAYMENT_PRICE_PRO = '$89.99/year';

export const BUTTON_HEIGHT = 60;
export const HEADER_HEIGHT = 60;

export const APP_STORE_LINK =
    'https://apps.apple.com/us/app/simplywise-bills-receipts/id1538521095';
export const PLAY_STORE_LINK =
    'https://play.google.com/store/apps/details?id=com.simplywise.mobile';
export const GOOGLE_MAPS_APIKEY = 'AIzaSyASA9FGBrkh_BB0hf0bTwmhhpXcLWdjE3E';

export const DATE_ANDROID_RESTART_PAYMENT_DATE = new Date(2023, 2, 10); // 2023-03-09

export const FAQ_LINK = `https://www.simplywise.com/blog/frequently-asked-questions-simplywise/`;
export const ROADMAP_LINK = `https://simplywise.feedbear.com/boards/feature-requests`;

export const RECONCILIATION_PLAY_STORE_URL =
    'https://play.google.com/store/apps/details?id=com.simplywise.mobile&listing=reconciliation1';
export const RECONCILIATION_APP_STORE_URL =
    'https://apps.apple.com/app/simplywise-receipt-scanner/id1538521095?ppid=d67817d7-9379-4ea1-ae5f-3180d58c0db8';
export const RECONCILIATION_APP_STORE_URL_NEW =
    'https://apps.apple.com/app/simplywise-receipt-scanner/id1538521095?ppid=8c67ddbe-578e-49a2-93b6-daacd9ad149f';
