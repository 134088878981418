import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import {
    IGetCostEstimationResponse,
    IGetPhotoInformationResponse,
    IGetXlsxExportResponse,
    IRetrieveCostEstimationResponse,
    IUploadImageResponse,
    ISharedCostEstimationResponse,
    IGetImageDataResponse,
} from './types';

export default class PublicService {
    public static uploadImage(image: Blob): Promise<IUploadImageResponse> {
        const data = new FormData();
        data.append('file', image);
        return axios
            .post('/public/upload-image', data)
            .then((value: AxiosResponse<IUploadImageResponse>) => {
                return value.data;
            });
    }

    public static getCostEstimation(
        image_id: string | undefined,
        prompt: string,
        current_estimation?: string,
        previous_prompt?: string
    ): Promise<IGetCostEstimationResponse> {
        const data = new FormData();
        if (image_id) data.append('image_id', image_id);
        data.append('prompt', prompt);
        data.append('format', 'csv');
        if (current_estimation)
            data.append('current_estimation', current_estimation);
        if (previous_prompt) data.append('previous_prompt', previous_prompt);
        data.append('visitor_id', localStorage.getItem('visitor_id') || '');
        return axios
            .post('/public/get-cost-estimation', data)
            .then((value: AxiosResponse<IGetCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static logCostEstimationResult(
        token: string,
        image_id: string,
        prompt: string,
        line_items: any,
        receipt_id: string | undefined,
        saved: boolean
    ): Promise<IRetrieveCostEstimationResponse> {
        const data = new FormData();
        data.append('image_id', image_id);
        data.append('prompt', prompt);
        data.append('line_items', line_items);
        if (receipt_id) data.append('receipt_id', receipt_id);
        data.append('saved', saved.toString());
        return axios
            .post('/public/log-cost-estimation-result', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Token ${token}`,
                },
            })
            .then((value: AxiosResponse<IRetrieveCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static getPhotoInformation(
        image_ids: string | string[],
        date?: string
    ): Promise<IGetPhotoInformationResponse> {
        return axios
            .post('/public/get-photo-information', {
                image_ids: Array.isArray(image_ids) ? image_ids : [image_ids],
                date,
            })
            .then((value: AxiosResponse<IGetPhotoInformationResponse>) => {
                return value.data;
            });
    }

    public static getImageAnalyze(
        image_id: string
    ): Promise<IGetCostEstimationResponse> {
        const data = new FormData();
        data.append('image_id', image_id);
        return axios
            .post('/public/analyze-image', data)
            .then((value: AxiosResponse<IGetCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static getXlsxExport(
        csv_data: string
    ): Promise<IGetXlsxExportResponse> {
        const data = new FormData();
        data.append('csv_data', csv_data);
        return axios
            .post('/public/get-xlsx-export', data)
            .then((value: AxiosResponse<IGetXlsxExportResponse>) => {
                return value.data;
            });
    }

    public static getEstimationData(
        url: string
    ): Promise<ISharedCostEstimationResponse> {
        const data = new FormData();
        data.append('url', url);
        return axios
            .post(`/public/shared-cost-estimation/get`, data)
            .then((value: AxiosResponse<ISharedCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static getImageData(
        image_id: string
    ): Promise<IGetImageDataResponse> {
        const data = new FormData();
        data.append('image_id', image_id);
        return axios
            .post(`/public/get-image-data`, data)
            .then((value: AxiosResponse<IGetImageDataResponse>) => {
                return value.data;
            });
    }

    public static sharedEstimateNotify(
        url: string,
        notification: 'seen'
    ): Promise<IGetImageDataResponse> {
        const data = new FormData();
        data.append('url', url);
        data.append('notification', notification);
        return axios
            .post(`/public/shared-cost-estimation/notify`, data)
            .then((value: AxiosResponse<IGetImageDataResponse>) => {
                return value.data;
            });
    }
}
