import jsPDF from 'jspdf';
import { toJpeg } from 'html-to-image';

function getImageDimensions(file: string) {
    return new Promise(function (resolved, rejected) {
        var i = new Image();
        i.onload = function () {
            resolved({ w: i.width, h: i.height });
        };
        i.src = file;
    });
}

export const downloadComponentAsPDF = async (
    elementId: string,
    filename: string
) => {
    const input = document.getElementById(elementId);
    if (input) {
        const imgData = await toJpeg(input, {
            backgroundColor: 'white',
            quality: 0.9,
        });
        const imageSize: any = await getImageDimensions(imgData);
        const pdf = new jsPDF();
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (imageSize.h * imgWidth) / imageSize.w;
        let heightLeft = (imageSize.h * imgWidth) / imageSize.w;
        let position = 0;
        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        pdf.save(`${filename}.pdf`);
    }
};
