import React, { useState } from 'react';
import { Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import theme from '../../../theme';
import {
    onWebAppCostEstimationEditTrade,
    onWebAppCostEstimationAddTrade,
    onWebAppCostEstimationDeleteTrade,
} from '../../../tracking/trackers';
import { useCostEstimator } from './CostEstimatorProvider';
const TradeDescriptionList = () => {
    const { width } = useWindowDimensions();
    const {
        gcDescriptions,
        setGcDescriptions,
        setDrafting,
        generateEstimate,
        setWasTradesEdited,
    } = useCostEstimator();
    const [editingTradeIndex, setEditingTradeIndex] = useState<number | null>(
        null
    );
    const [editedDescription, setEditedDescription] = useState<string[]>([]);
    const [showAllBullets, setShowAllBullets] = useState<{
        [key: number]: boolean;
    }>({});
    const [isAddingNewBullet, setIsAddingNewBullet] = useState(false);
    const handleEdit = (tradeIndex: number) => {
        setEditingTradeIndex(tradeIndex);
        setEditedDescription(
            gcDescriptions.trades[tradeIndex].description.split('\n')
        );
        onWebAppCostEstimationEditTrade({
            trade: gcDescriptions.trades[tradeIndex].section_title,
            estimate_id: gcDescriptions.estimate_id,
        });
    };

    const handleSave = (tradeIndex: number) => {
        const cleanedDescription = editedDescription.filter((item) => {
            const cleanedItem = item.replace(/^[•○\-\s]+/, '').trim();
            return cleanedItem.length > 0;
        });
        const updatedTrades = [...gcDescriptions.trades];
        updatedTrades[tradeIndex].description = cleanedDescription.join('\n');
        setGcDescriptions((prev: any) => ({
            ...prev,
            trades: updatedTrades,
        }));
        setWasTradesEdited(true);
        setEditingTradeIndex(null);
    };

    const handleAddTask = (tradeIndex: number) => {
        if (editingTradeIndex === tradeIndex) {
            setEditedDescription([...editedDescription, '']);
            onWebAppCostEstimationAddTrade({
                trade: gcDescriptions.trades[tradeIndex].section_title,
                estimate_id: gcDescriptions.estimate_id,
            });
        } else {
            const currentTasks =
                gcDescriptions.trades[tradeIndex].description.split('\n');
            setEditedDescription([...currentTasks, '']);
            setEditingTradeIndex(tradeIndex);
        }
    };

    const handleDelete = (tradeIndex: number) => {
        onWebAppCostEstimationDeleteTrade({
            trade: gcDescriptions.trades[tradeIndex].section_title,
            estimate_id: gcDescriptions.estimate_id,
        });
        const updatedTrades = gcDescriptions.trades.filter(
            (item: any, index: number) => index !== tradeIndex
        );
        setGcDescriptions({ ...gcDescriptions, trades: updatedTrades });
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '90%',
            }}
        >
            <div
                style={{
                    flex: 1,
                    backgroundColor: '#fff',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 10,
                }}
            >
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    {gcDescriptions.trades.map((trade: any, index: number) => {
                        const bulletPoints = trade.description.split('\n');
                        const shouldShowAll = showAllBullets[index];
                        const displayBullets = shouldShowAll
                            ? bulletPoints
                            : bulletPoints.slice(0, 4);

                        return (
                            <div
                                key={index}
                                style={{
                                    backgroundColor: '#f8f9fa',
                                    marginBottom: 16,
                                    borderRadius: 12,
                                    padding: 16,
                                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 8,
                                        paddingLeft: 16,
                                    }}
                                >
                                    <div style={{ flex: 1, paddingRight: 10 }}>
                                        <h3
                                            style={{
                                                fontSize:
                                                    width < 768
                                                        ? trade.section_title
                                                              .length > 15
                                                            ? 16
                                                            : 18
                                                        : trade.section_title
                                                              .length > 15
                                                        ? 18
                                                        : 22,
                                                fontWeight: 'bold',
                                                color: '#333',
                                                margin: 0,
                                            }}
                                        >
                                            {trade.section_title}
                                        </h3>
                                    </div>
                                    {editingTradeIndex === index ? (
                                        !isAddingNewBullet && (
                                            <button
                                                onClick={() =>
                                                    handleSave(index)
                                                }
                                                style={{
                                                    border: 'none',
                                                    background: 'none',
                                                    cursor: 'pointer',
                                                    padding: '4px',
                                                    opacity: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    transition:
                                                        'color 0.2s ease',
                                                }}
                                            >
                                                <CheckIcon
                                                    style={{
                                                        fontSize:
                                                            width < 768
                                                                ? 22
                                                                : 26,
                                                        color: theme.colors
                                                            .primary400Light,
                                                    }}
                                                    sx={{
                                                        '&:hover': {
                                                            color: theme.colors
                                                                .primary400,
                                                        },
                                                    }}
                                                />
                                            </button>
                                        )
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 10,
                                                paddingTop: 4,
                                            }}
                                        >
                                            {trade.section_title !==
                                                'Project' && (
                                                <button
                                                    onClick={() =>
                                                        handleDelete(index)
                                                    }
                                                    style={{
                                                        border: 'none',
                                                        background: 'none',
                                                        cursor: 'pointer',
                                                        padding: '4px',
                                                        opacity: 0.6,
                                                    }}
                                                >
                                                    <DeleteOutlineIcon
                                                        style={{
                                                            fontSize:
                                                                width < 768
                                                                    ? 18
                                                                    : 22,
                                                            color: theme.colors
                                                                .error,
                                                        }}
                                                    />
                                                </button>
                                            )}
                                            <button
                                                onClick={() =>
                                                    handleEdit(index)
                                                }
                                                style={{
                                                    border: 'none',
                                                    background: 'none',
                                                    cursor: 'pointer',
                                                    padding: '4px',
                                                    opacity: 0.6,
                                                }}
                                            >
                                                <EditIcon
                                                    style={{
                                                        fontSize:
                                                            width < 768
                                                                ? 18
                                                                : 22,
                                                        color: theme.colors
                                                            .secondary,
                                                    }}
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {editingTradeIndex === index ? (
                                    <>
                                        {editedDescription.map(
                                            (item, itemIndex) => (
                                                <input
                                                    key={itemIndex}
                                                    value={
                                                        item.startsWith('○')
                                                            ? item
                                                            : `○ ${item.replace(
                                                                  /^[•\-\s]+/,
                                                                  ''
                                                              )}`
                                                    }
                                                    onChange={(e) => {
                                                        const newDesc = [
                                                            ...editedDescription,
                                                        ];
                                                        newDesc[itemIndex] =
                                                            e.target.value.startsWith(
                                                                '○'
                                                            )
                                                                ? e.target.value
                                                                : `○ ${e.target.value.replace(
                                                                      /^[•\-\s]+/,
                                                                      ''
                                                                  )}`;
                                                        setEditedDescription(
                                                            newDesc
                                                        );
                                                    }}
                                                    style={{
                                                        fontSize:
                                                            width < 768
                                                                ? 14
                                                                : 16,
                                                        marginBottom: 8,
                                                        color: '#333',
                                                        width: '100%',
                                                        border: 'none',
                                                        outline: 'none',
                                                        background:
                                                            'transparent',
                                                        padding: '4px 0',
                                                        paddingLeft: 16,
                                                        lineHeight: '24px',
                                                    }}
                                                    autoFocus={itemIndex === 0}
                                                />
                                            )
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {displayBullets.map(
                                            (
                                                item: string,
                                                itemIndex: number
                                            ) => (
                                                <div
                                                    key={`trade-${index}-description-${itemIndex}`}
                                                    style={{
                                                        display: 'flex',
                                                        marginBottom: 8,
                                                        alignItems:
                                                            'flex-start',
                                                        lineHeight: '24px',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            width:
                                                                width < 768
                                                                    ? 20
                                                                    : 24,
                                                            color: '#666',
                                                            fontSize:
                                                                width < 768
                                                                    ? 16
                                                                    : 28,
                                                            lineHeight: '24px',
                                                            justifyContent:
                                                                'flex-start',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        •
                                                    </span>
                                                    <span
                                                        style={{
                                                            flex: 1,
                                                            fontSize:
                                                                width < 768
                                                                    ? 14
                                                                    : 16,
                                                            color: '#333',
                                                            paddingTop: 2,
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        {item.replace(
                                                            /^[•○\-\s]+/,
                                                            ''
                                                        )}
                                                    </span>
                                                </div>
                                            )
                                        )}

                                        {bulletPoints.length > 4 && (
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                {!shouldShowAll && (
                                                    <div
                                                        style={{
                                                            background:
                                                                'linear-gradient(to bottom, rgba(255,255,255,0), #f8f9fa)',
                                                            position:
                                                                'absolute',
                                                            bottom: 24,
                                                            left: 0,
                                                            right: 0,
                                                            height: 40,
                                                        }}
                                                    />
                                                )}
                                                <button
                                                    onClick={() => {
                                                        setShowAllBullets(
                                                            (prev) => ({
                                                                ...prev,
                                                                [index]:
                                                                    !prev[
                                                                        index
                                                                    ],
                                                            })
                                                        );
                                                    }}
                                                    style={{
                                                        marginTop: 8,
                                                        paddingLeft: 16,
                                                        color: '#00bcd4',
                                                        fontWeight: '500',
                                                        textAlign: 'right',
                                                        background: 'none',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {shouldShowAll
                                                        ? 'Show Less'
                                                        : 'Show More...'}
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}

                                {editingTradeIndex === index && (
                                    <button
                                        onClick={() => {
                                            if (
                                                isAddingNewBullet &&
                                                editingTradeIndex !== null
                                            ) {
                                                handleSave(editingTradeIndex);
                                                setIsAddingNewBullet(false);
                                            } else {
                                                setIsAddingNewBullet(true);
                                                handleAddTask(index);
                                            }
                                        }}
                                        style={{
                                            alignItems: 'center',
                                            marginTop: 8,
                                            border: 'none',
                                            background: 'none',
                                            cursor: 'pointer',
                                            color: '#00bcd4',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {isAddingNewBullet ? '✓' : '+ Add'}
                                    </button>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>

            <div style={{ height: 80 }} />

            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: `${width < 768 ? 10 : 15}px 16px`,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    backgroundColor: theme.colors.white,
                    boxShadow:
                        '0px -12px 24px -8px rgba(0, 0, 0, 0.03), 0px -4px 8px -4px rgba(0, 0, 0, 0.02)',
                    background:
                        'linear-gradient(180deg, rgba(255,255,255,0.85) 0%, rgba(255,255,255,0.95) 30%, rgba(255,255,255,1) 60%)',
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        setDrafting(true);
                        generateEstimate();
                        setGcDescriptions(undefined);
                    }}
                    style={{
                        color: theme.colors.white,
                        boxShadow: 'none',
                        borderRadius: 28,
                        height: 56,
                        width: '90%',
                        fontWeight: '600',
                        fontSize: 18,
                        backgroundColor: theme.colors.primary400,
                        textTransform: 'none',
                    }}
                    sx={{
                        '&:hover': {
                            backgroundColor: `${theme.colors.primary400Light} !important`,
                        },
                    }}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default TradeDescriptionList;
