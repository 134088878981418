import { emitter } from '@marvelapp/react-ab-test';

const experimentIndex = 0;
const PaymentVariants = [
    {
        name: 'CE-Payment-Exp-Jan23',
        variants: {
            A: 'baseline',
            B: 'StarsIconIncluded',
            C: 'InAppBulletPoints',
        },
    },
];

emitter.defineVariants(
    PaymentVariants[experimentIndex].name,
    Object.keys(PaymentVariants[experimentIndex].variants)
);

export default PaymentVariants[experimentIndex];
