import { Button, styled, Typography } from '@mui/material';
import theme from '../../theme';

export const SPACE_PADDING = 0;

export const CARD_DIMENSIONS = {
    width: 175, // bc gap
    height: 160,
    gap: 8,
};

export const CardContainer = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'noHover',
})<{ isSelected?: boolean; noHover?: boolean }>(({ isSelected, noHover }) => ({
    'display': 'flex',
    'flexDirection': 'column',
    'boxShadow': theme.defaultShadow,
    'borderWidth': 0,
    'margin': CARD_DIMENSIONS.gap,
    'width': CARD_DIMENSIONS.width,
    'height': CARD_DIMENSIONS.height,
    'borderRadius': theme.roundness,
    'alignItems': 'center',
    'justifyContent': 'space-around',
    'textTransform': 'none',
    'backgroundColor': isSelected ? theme.colors.primary50 : theme.colors.white,
    '&:hover': {
        backgroundColor: noHover
            ? isSelected
                ? theme.colors.primary50
                : theme.colors.white
            : theme.colors.folderHover,
        borderWidth: 0,
        boxShadow: theme.defaultShadowDark,
    },
}));

export const CardIconContainer = styled('div')({
    position: 'absolute',
    top: '20px',
    height: `${CARD_DIMENSIONS.height / 2}px`,
    display: 'flex',
    alignItems: 'center',
});

export const CardBodyContainer = styled(Typography)(() => ({
    position: 'absolute',
    fontSize: '16px',
    bottom: '20px',
    fontWeight: 700,
    lineHeight: 1,
    color: theme.colors.blue100,
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
}));
