import { CircularProgress, Divider } from '@mui/material';
import moment from 'moment';
// @ts-ignore
import { useExperiment } from '@marvelapp/react-ab-test';
import { Body, BodyBold, OnboardingH5 } from '../../../components/Text';
import { BulletPoint } from '../PaymentTiers/style';
import TrustStars from '../../../assets/landing/stars_4.8.svg';
import PaymentVariants from './components/PaymentVariants';

type ICheckoutHeaderProps = {
    loading: boolean;
    paymentAmount: string;
    discountPercentage?: number | undefined;
    variant?: string;
};

const CheckoutHeader = (props: ICheckoutHeaderProps) => {
    const { loading, paymentAmount, discountPercentage, variant } = props;

    const total = parseFloat(paymentAmount.slice(1, 6)); // $99.99 => 99.99
    const totalWithDiscount = parseFloat(
        discountPercentage
            ? (total * ((100 - discountPercentage) / 100)).toFixed(2)
            : total.toFixed(2)
    );
    const amountPerMonth = paymentAmount.includes('yearly')
        ? (totalWithDiscount / 12).toFixed(2).toString()
        : totalWithDiscount.toString();

    const sevenDaysFromNow = moment(
        new Date().setDate(new Date().getDate() + 7)
    ).format('MMM D, YYYY');

    const expName = PaymentVariants.name;
    const { selectVariant } = useExperiment(expName);
    const abVariant = selectVariant(PaymentVariants.variants);

    return (
        <div style={{ width: '100%', marginBottom: 20 }}>
            {variant === 'cost-estimator' &&
                abVariant === 'StarsIconIncluded' && (
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 15,
                        }}
                    >
                        <img src={TrustStars} alt="" width={400} height={120} />
                    </div>
                )}
            <OnboardingH5 style={{ marginTop: 20, marginBottom: 20 }}>
                Start your 7-day free trial
            </OnboardingH5>
            <Body style={{ marginTop: 20, textAlign: 'center' }}>
                No commitment. Cancel anytime.
            </Body>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 10,
                }}
            >
                {variant === 'ai-receptionist' ? (
                    <>
                        <BulletPoint text={`Personalized AI Receptionist`} />
                        <BulletPoint
                            text={`Busy and unanswered call forwarding`}
                        />
                        <BulletPoint
                            text={`Text summary of receptionist calls`}
                        />
                        <BulletPoint text={`Call recording and playback`} />
                        <BulletPoint text={`Spam detection`} />
                    </>
                ) : variant === 'cost-estimator' ? (
                    abVariant === 'baseline' ||
                    abVariant === 'StarsIconIncluded' ? (
                        <>
                            <BulletPoint text={`Unlimited project estimates`} />
                            <BulletPoint
                                text={`Spreadsheet download for easy editing`}
                            />
                            <BulletPoint
                                text={`Links for materials at cheapest live & local price`}
                            />
                            <BulletPoint
                                text={`Instant construction project plans`}
                            />
                        </>
                    ) : (
                        <>
                            <BulletPoint text={`Unlimited cost estimations`} />
                            <BulletPoint
                                text={`Unlimited invoice generation`}
                            />
                            <BulletPoint
                                text={`Real-time accurate material prices`}
                            />
                        </>
                    )
                ) : null}
            </div>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            {variant === 'ai-receptionist' ? (
                <>
                    <Body
                        style={{
                            fontSize: 12,
                            paddingBottom: 20,
                        }}
                    >
                        7 days free, then $4.99/month starting from{' '}
                        {sevenDaysFromNow}
                    </Body>
                </>
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 20,
                        }}
                    >
                        <BodyBold>Total due today*</BodyBold>
                        <Body>$0.00</Body>
                    </div>
                    {discountPercentage ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: 20,
                            }}
                        >
                            <Body>Discount applied</Body>
                            <Body>{`${discountPercentage}%`}</Body>
                        </div>
                    ) : null}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 20,
                        }}
                    >
                        <Body>Due on {sevenDaysFromNow}</Body>
                        <Body>{`$${amountPerMonth}`}</Body>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            marginBottom: 20,
                        }}
                    >
                        <Body
                            style={{ fontSize: 13, fontWeight: '600' }}
                        >{`*$${totalWithDiscount} billed ${
                            paymentAmount.includes('yearly')
                                ? 'annually'
                                : 'monthly'
                        } after trial`}</Body>
                    </div>
                    <Divider style={{ marginBottom: 20, marginTop: 20 }} />
                </>
            )}
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress
                        color={'primary'}
                        style={{
                            width: 60,
                            height: 60,
                            marginTop: 10,
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default CheckoutHeader;
