/**
 * Formats a date into a short month string format
 * @param date - The date to format
 * @returns A string in the format "MMM DD, YYYY" (e.g., "Nov 15, 2024")
 * @example
 * formatDateWithShortMonth(new Date("2024-11-15"))  // Returns "Nov 15, 2024"
 */
export const formatDateWithShortMonth = (date: Date) =>
    date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
