import React from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

interface DraftingAnimationProps {
    draftingText?: string;
    animation: React.ReactNode;
}

const DraftingAnimation: React.FC<DraftingAnimationProps> = ({
    draftingText,
    animation,
}) => {
    const { width } = useWindowDimensions();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 10,
                justifyContent: 'center',
                width: Math.min(width * 0.8, 400),
            }}
        >
            <div
                style={{ fontSize: width < 768 ? 22 : 26, textAlign: 'center' }}
            >
                {draftingText}
            </div>
            {animation}
        </div>
    );
};

export default DraftingAnimation;
